import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from 'redux-thunk';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { persistStore, persistReducer, createTransform } from 'redux-persist';

import reducer from "./reducer";

const persistConfig = {
    key: 'root',
    storage: AsyncStorage,
    whitelist: ['token', 'branch', 'showRecordThumbnail']
  };

const rootReducer = combineReducers({ reducer: persistReducer(persistConfig, reducer) });

export const store = createStore(rootReducer, applyMiddleware(thunk));
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof rootReducer>