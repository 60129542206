import { LocationObject } from "expo-location";
import {
    STORE_TOKEN,
    DESTROY_TOKEN,
    STORE_BRANCH,
    DESTROY_BRANCH,
    STORE_QR_CODE,
    DESTROY_QR_CODE,
    STORE_LOCATION,
    DESTROY_LOCATION,
    STORE_LAST_UPDATE_TIME,
    DESTROY_LAST_UPDATE_TIME,
    SET_EEROR_MESSAGE,
    REMOVE_EEROR_MESSAGE,
    ADD_HISTORY,
    DESTROY_HISTORY,
    STORE_TOTAL_HISTORY_PAGE,
    DESTROY_TOTAL_HISTORY_PAGE,
    STORE_CURRENT_HISTORY_PAGE,
    DESTROY_CURRENT_HISTORY_PAGE,
    UPDATE_HISTORY_ITEM,
    STORE_HISTORY_BEFORE_DATE,
    DESTROY_HISTORY_BEFORE_DATE,
    SET_SHOW_HISTORY_BEFORE_DATE_DIALOG,
    EMPTY_HISTORY,
    // SET_IS_FORCE_EEROR_MESSAGE,
    SET_IS_NETWORK_CONNECTED,
    UPDATE_BRANCH_MESSAGE,
    SET_SHOW_RECORD_THUMBNAIL
} from "./types";

interface AppState {
    token: string | null,
    branch: string | null,
    qrCode: string | null,
    location: LocationObject | null,
    lastUpdateTime: string | null,
    errorMessage: string | null,
    history: Array<any> | null,
    totalHistoryPage: number,
    currentHistoryPage: number,
    historyBeforeDate: moment.Moment | null,
    enabledSelectHistoryBeforeDate: boolean,
    showHistoryBeforeDateDialog: boolean,
    isNetworkConnected: boolean,
    showRecordThumbnail: boolean
}

const initialState : AppState = {
    token: null,
    branch: null,
    qrCode: null,
    location: null,
    lastUpdateTime: null,
    errorMessage: null,
    // isForceErrorMessage: false,
    history: null,
    totalHistoryPage: 0,
    currentHistoryPage: 0,
    historyBeforeDate: null,
    enabledSelectHistoryBeforeDate: false,
    showHistoryBeforeDateDialog: false,
    isNetworkConnected: true,
    showRecordThumbnail: false
}

/*
import AsyncStorage from '@react-native-async-storage/async-storage';
import StorageKeys from "../constants/StorageKeys";
*/

const reducer = (state = initialState, action) => {
    switch (action.type) {
        /** State token */
        case STORE_TOKEN:
            return {
                ...state,
                token: action.payload
            };
        case DESTROY_TOKEN:
            return {
                ...state,
                token: null
            };

        /** State branch */
        case STORE_BRANCH:
            return {
                ...state,
                branch: action.payload
            };
        case DESTROY_BRANCH:
            return {
                ...state,
                branch: null
            };

        /** State QR code */
        case STORE_QR_CODE:
            return {
                ...state,
                qrCode: action.payload
            };
        case DESTROY_QR_CODE:
            return {
                ...state,
                qrCode: null
            };

        case UPDATE_BRANCH_MESSAGE:
            if (state.branch.branch_message !== action.payload) {
                const newBranch = { ...state.branch };
                newBranch.branch_message = action.payload;

                return {
                    ...state,
                    branch: newBranch
                }
            }
            return {
                ...state,
            }
        /** Location storage */
        case STORE_LOCATION:
            return {
                ...state,
                location: action.payload
            };
        case DESTROY_LOCATION:
            return {
                ...state,
                location: null
            };

        /** Last update time */
        case STORE_LAST_UPDATE_TIME:
            return {
                ...state,
                lastUpdateTime: action.payload
            };
        case DESTROY_LAST_UPDATE_TIME:
            return {
                ...state,
                lastUpdateTime: null
            };

        /** Error message */
        case SET_EEROR_MESSAGE:
            return {
                ...state,
                errorMessage: action.payload
            };
        case REMOVE_EEROR_MESSAGE:
            return {
                ...state,
                errorMessage: null
            };

        // case SET_IS_FORCE_EEROR_MESSAGE:
        //     return {
        //         ...state,
        //         isForceErrorMessage: action.payload
        //     };

        /** History */
        case ADD_HISTORY:
            let oriHistory = [];
            if (state.history) {
                oriHistory = [...state.history];
            }
            const newHistory = oriHistory.concat(action.payload);

            return {
                ...state,
                history: newHistory
            };
        case DESTROY_HISTORY:
            return {
                ...state,
                history: null
            };
        case EMPTY_HISTORY:
            return {
                ...state,
                history: []
            };

        case UPDATE_HISTORY_ITEM:
            const history = [...state.history];
            const payload = action.paylaod;
            let itemIndex = -1;
            history?.forEach((item, index) => {
                if (item.id == payload.id) {
                    itemIndex = index;
                }
            })
            if (itemIndex > -1) {
                history[itemIndex].remarks = payload.remarks;
            }

            return {
                ...state,
                history: history
            };


        /** History Page */
        case STORE_TOTAL_HISTORY_PAGE:
            return {
                ...state,
                totalHistoryPage: action.payload
            };
        case DESTROY_TOTAL_HISTORY_PAGE:
            return {
                ...state,
                totalHistoryPage: 0
            };

        case STORE_CURRENT_HISTORY_PAGE:
            return {
                ...state,
                currentHistoryPage: action.payload
            };
        case DESTROY_CURRENT_HISTORY_PAGE:
            return {
                ...state,
                currentHistoryPage: 0
            };

        /** History Before Date */
        case STORE_HISTORY_BEFORE_DATE:
            return {
                ...state,
                historyBeforeDate: action.payload
            };
        case DESTROY_HISTORY_BEFORE_DATE:
            return {
                ...state,
                historyBeforeDate: null
            };

        case SET_SHOW_HISTORY_BEFORE_DATE_DIALOG:
            return {
                ...state,
                showHistoryBeforeDateDialog: action.payload
            };

        /** Network */
        case SET_IS_NETWORK_CONNECTED:
            return {
                ...state,
                isNetworkConnected: action.payload
            };

        case SET_SHOW_RECORD_THUMBNAIL:
            return {
                ...state,
                showRecordThumbnail: action.payload
            };

        default:
            return state;
    }
}

export default reducer;