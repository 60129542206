
import Constants from 'expo-constants'
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { ActivityIndicator, ScrollView, StyleSheet } from 'react-native';
import { useSelector, useDispatch } from 'react-redux'
import QRCode from 'react-native-qrcode-svg';

import Colors from '../constants/Colors';
import { Text, View } from '../components/Themed';
import { AlertBox } from '../components/AlertBox';
import { getQRCode as getQRCodeAction } from '../store/action';
import { RootTabScreenProps } from '../types';
import { RootState } from '../store/store';

export default function CheckInScreen({ navigation }: RootTabScreenProps<'CheckIn'>) {

  const reportIntervalInSecond = Constants.manifest?.extra?.reportInterval / 1000;

  const [time, setTime] = useState(null);
  const [nextFetchingTime, setNextFetchingTime] = useState(null);
  const dispatch = useDispatch();
  const { token, branch, qrCode, location, lastUpdateTime, errorMessage, isNetworkConnected } = useSelector((state: RootState) => state.reducer);


  const [isNeedScrollView, setNeedScrollView] = useState(false);
  const [containerHeight, setContainerHeight] = useState(0);
  const [timeHeight, setTimeHeight] = useState(0);
  const [dateHeight, setDateHeight] = useState(0);
  const [branchHeight, setBranchHeight] = useState(0);
  const [scrollBranchMessageHeight, setScrollBranchMessageHeight] = useState(0);
  const [branchMessageHeight, setBranchMessageHeight] = useState(0);

  useEffect(() => {
    setTime(moment().clone());
    const intervalId = setInterval(() => {
      setTime(moment().clone());
    }, 1000);

    return () => {
      setTime(null);
      clearInterval(intervalId)
    }
  }, []);


  const getQRCode = async (token: String, isNetworkConnected: boolean) => {
    console.log('Get QR Code')
    if (isNetworkConnected) {
      console.log('Network connected')

      dispatch(getQRCodeAction(token)).then((value) => {
        console.log('Received QR Code')

        const nextTime = moment().clone().add(reportIntervalInSecond, 's');
        setNextFetchingTime(nextTime)

        if (value.name == "resolve") {
          return Promise.resolve(`Success`);
        } else {
          return Promise.resolve(`Error`);
        }
      })
    } else {
      console.log('Network not connected')
      const nextTime = moment().clone().add(reportIntervalInSecond, 's');
      setNextFetchingTime(nextTime)
      return Promise.resolve(`Error`);
    }
  }

  useEffect(() => {
    console.log("useEffect")
    let intervalId: NodeJS.Timer | null = null;
    // reset remainSecond after fetching QR code
    

    const fetchData = async () => {
     await getQRCode(token, isNetworkConnected).then(() => {
        console.log('next')
        intervalId = setInterval(() => {
          getQRCode(token, isNetworkConnected)
        }, Constants.manifest?.extra?.reportInterval);
      })
    }
    fetchData();


    return () => {
      if (intervalId != null) {
        clearInterval(intervalId)
      }
    }
  }, [token, isNetworkConnected]);

  // Check if scroll view needed
  useEffect(() => {
    if (timeHeight > 0 && dateHeight > 0 && branchHeight > 0 && (branchMessageHeight > 0 || scrollBranchMessageHeight > 0) && containerHeight > 0) {
      if (isNeedScrollView && scrollBranchMessageHeight > 0) {
        if (timeHeight + dateHeight + branchHeight + scrollBranchMessageHeight < containerHeight) {
          setNeedScrollView(false)
        }
      } else if (!isNeedScrollView && branchMessageHeight > 0) {
        if (timeHeight + dateHeight + branchHeight + branchMessageHeight > containerHeight) {
          setNeedScrollView(true)
        }
      }
    }
  }, [isNeedScrollView,
    timeHeight, dateHeight,
    branchHeight, branchMessageHeight, scrollBranchMessageHeight,
    containerHeight
  ])

  return (
    <View style={styles.container}>
      {qrCode != null ? (
        <View
          style={[styles.container, {
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            padding: '4vw'
          }]}>

          <View
            onLayout={(event) => {
              var { x, y, width, height } = event.nativeEvent.layout;
              setContainerHeight(height)
            }}
            style={[{
              width: '40%',
              flexDirection: "column",
              alignItems: 'center',
              justifyContent: 'center'
            }]}>
            <Text
              onLayout={(event) => {
                var { x, y, width, height } = event.nativeEvent.layout;
                setTimeHeight(height)
              }}
              style={styles.time}
              lightColor="rgba(0,0,0,0.8)"
              darkColor="rgba(255,255,255,0.8)">
              {time?.format('HH:mm')}
            </Text>
            <Text
              onLayout={(event) => {
                var { x, y, width, height } = event.nativeEvent.layout;
                setDateHeight(height)
              }}
              style={styles.date}
              lightColor="rgba(0,0,0,0.8)"
              darkColor="rgba(255,255,255,0.8)">
              {time?.format('ddd, MMM D')}
            </Text>

            <Text
              onLayout={(event) => {
                var { x, y, width, height } = event.nativeEvent.layout;
                setBranchHeight(height)
              }}
              style={styles.branch}
              lightColor="rgba(0,0,0,0.8)"
              darkColor="rgba(255,255,255,0.8)">
              {branch?.name}
            </Text>

            {isNeedScrollView ?
              (
                <ScrollView
                  style={{ marginTop: 20 }}
                  contentContainerStyle={{
                    flexGrow: 1,
                    justifyContent: 'center',
                  }}
                >
                  <Text onLayout={(event) => {
                    var { x, y, width, height } = event.nativeEvent.layout;
                    setScrollBranchMessageHeight(height)
                  }}
                    style={styles.branchMessage}
                    lightColor="rgba(0,0,0,0.8)"
                    darkColor="rgba(255,255,255,0.8)">
                    {branch?.branch_message}
                  </Text>
                </ScrollView>
              ) : (
                <Text onLayout={(event) => {
                  var { x, y, width, height } = event.nativeEvent.layout;
                  setBranchMessageHeight(height)
                }}
                  style={styles.branchMessage}
                  lightColor="rgba(0,0,0,0.8)"
                  darkColor="rgba(255,255,255,0.8)">
                  {branch?.branch_message}
                </Text>
              )
            }
          </View>

          <View style={{
            width: '10vw',
            height: 1
          }}></View>

          <View style={[{
            width: '50%',
            alignItems: 'center',
            justifyContent: 'center'
          }]}>
            {(moment().isBefore(nextFetchingTime)) ? (
            <QRCode
              size={400}
              value={qrCode}
              style={[{
                width: '50%',
              }]}
              
            />
            ): null }
          </View>


          <View style={{
            position: 'absolute',
            bottom: 20,
            left: 12
          }}>
            <Text
              style={styles.footerText}
              lightColor="rgba(0,0,0,0.8)"
              darkColor="rgba(255,255,255,0.8)">
              Last Updated time: {lastUpdateTime} ({(nextFetchingTime != null && moment().isBefore(nextFetchingTime)) ? (
                nextFetchingTime.diff(moment().clone(), 'seconds')
              ) : 0})
            </Text>
            <Text
              style={styles.footerText}
              lightColor="rgba(0,0,0,0.8)"
              darkColor="rgba(255,255,255,0.8)">
              Last Location: {location?.coords?.latitude ? location?.coords?.latitude : 0}, {location?.coords?.longitude ? location?.coords?.longitude : 0}
            </Text>
          </View>
        </View>) :
        (<View style={styles.loadingView}>
          <ActivityIndicator size="large" />
        </View>)}

      <AlertBox message={errorMessage?.toString()} />
    </View >
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },

  time: {
    fontSize: '9vw',
  },
  date: {
    fontSize: '4vw',
  },
  branch: {
    marginTop: '4vh',
    fontSize: '3.5vw',
    color: Colors.light.tint,
  },
  branchMessage: {
    marginTop: 20,
    fontSize: '2vw',
    minHeight:'100px'
  },
  qrCode: {
    width: 'auto',
    height: 'auto',
  },
  footerText: {
    fontSize: '1vw',
  },

  loadingView: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center'
  }
});
