import React, { useState } from 'react';
import { StyleSheet, ScrollView, Pressable, Linking, Modal, Switch } from 'react-native';
import { useDispatch, useSelector } from 'react-redux'

import { Text, View } from '../components/Themed';
import { Cell, Section, TableView } from 'react-native-tableview-simple';
import { signOut } from '../store/action';
import { REMOVE_EEROR_MESSAGE, SET_SHOW_RECORD_THUMBNAIL } from '../store/types';
import { RootState } from '../store/store';
import Constants from 'expo-constants';
import { Portal } from 'react-native-paper';
import Colors from '../constants/Colors';


export default function SettingsScreen() {
  const dispatch = useDispatch();
  const [showTnc, setShowTnc] = useState(false);
  const { branch, location, showRecordThumbnail } = useSelector((state: RootState) => state.reducer)

  function signOutAction() {
    dispatch({
      type: REMOVE_EEROR_MESSAGE
    })
    dispatch(signOut(''))
  }

  function setShowRecordThumbnail(value: Boolean) {
    console.debug('setShowRecordThumbnail', value)
    dispatch({
      type: SET_SHOW_RECORD_THUMBNAIL,
      payload: value
    })
  }

  const latitude = location?.coords?.latitude ? location?.coords?.latitude : branch.latitude;
  const longitude = location?.coords?.longitude ? location?.coords?.longitude : branch.longitude;

  return (
    <View style={[styles.container, {
      flexDirection: "column"
    }]}>
      <iframe
        style={{ width: '100%', height: 250, border: 'none' }}
        src={`https://maps.google.com/maps?q=${latitude},${longitude}&t=&z=18&ie=UTF8&iwloc=&output=embed`} allowFullScreen>
      </iframe>
      <ScrollView style={[styles.stage]} contentContainerStyle={[styles.container, { justifyContent: "flex-start" }]}>
        <TableView appearance="light" style={{ width: '100%', marginLeft: '10%', marginRight: '10%' }}>
          <Section roundedCorners hideSurroundingSeparators>
            <Cell
              cellContentView={

                <View style={{ paddingTop: 12, paddingBottom: 12 }}>
                  <Text
                    style={{
                      fontSize: 12
                    }}
                    lightColor="rgba(0,0,0,0.8)"
                    darkColor="rgba(255,255,255,0.8)">
                    Logged in as:
                  </Text>
                  <Text
                    style={{
                      fontSize: 20
                    }}
                    lightColor="rgba(0,0,0,0.8)"
                    darkColor="rgba(255,255,255,0.8)">
                    {branch.company.name}
                  </Text>
                  <Text
                    style={{
                      fontSize: 18
                    }}
                    lightColor="rgba(0,0,0,0.8)"
                    darkColor="rgba(255,255,255,0.8)">
                    {branch.name}
                  </Text>
                </View>
              }
            />
          </Section>

          <Section roundedCorners hideSurroundingSeparators separatorInsetRight={15}>
            <Cell 
            title="Show record thumbnail"
            cellAccessoryView={<Switch
              onValueChange={(value: Boolean) => {
                // console.debug('value', value);
                setShowRecordThumbnail(value)
              }}
              value={showRecordThumbnail}
              />}
            contentContainerStyle={{ paddingVertical: 4 }} // Adjust height
            ></Cell>
          </Section>

          <Section roundedCorners hideSurroundingSeparators separatorInsetRight={15}>
            <Pressable onPress={() => {
              setShowTnc(true)
            }}>
              <Cell cellStyle="Basic" title="Terms and Conditions" roundedCorners hideSurroundingSeparators />
            </Pressable>
            <Pressable onPress={signOutAction}>
              <Cell cellStyle="Basic" title="Logout" roundedCorners hideSurroundingSeparators />
            </Pressable>

          </Section>

          <Portal>

            <Modal visible={showTnc}>
                <View style={styles.tncModelHeader}>
                    <Pressable onPress={() => { setShowTnc(false) }}>
                        <Text style={styles.tncModelHeaderButton}>Back</Text>
                    </Pressable>
                </View>

                <iframe
                  style={{ width: '100%', height: '100%', border: 'none' }}
                  src={Constants.manifest?.extra?.tncURL} allowFullScreen>
                </iframe>
            </Modal>

          </Portal>

        </TableView>

      </ScrollView >
    </View>
  );
}
const cellPropsCustom = {
  cellStyle: 'Basic',
  title: 'Basic Custom',
  backgroundColor: 'black',
};

const styles = StyleSheet.create({
  stage: {
    backgroundColor: '#EFEFF4',
    paddingLeft: 20,
    paddingRight: 20,
    width: '100%'
  },
  container: {
    flex: 1,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
  map: {
    height: 250,
    width: '100%'
  },


  tncModelHeader: {
    width: '100%',
    backgroundColor: Colors.light.primary,
    padding: 20,
  },

  tncModelHeaderButton: {
    color: Colors.light.primaryTint,
    fontSize: 18
  },
  tncModelContent: {
    backgroundColor: '#EFEFF4',
    paddingLeft: 20,
    paddingRight: 20,
    width: '100%',
    flexGrow: 1
  },
});
