import React, { useEffect } from 'react';
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native';
import { useSelector, useDispatch } from 'react-redux'

import AwesomeAlert from 'react-native-awesome-alerts';

import Colors from '../constants/Colors';
import { REMOVE_EEROR_MESSAGE } from '../store/types';

/**
 * disableManualDismiss not work now
 * @param props 
 * @returns 
 */
export const AlertBox = (props: { message: string; title?: string; 
    dismissCallback?: Function; confirmCallback?: Function; canceCallback?: Function; 
    disableManualDismiss?: boolean
}) => {

    const [isShowingAlertBox, setShowingAlertBox] = React.useState(false);
    const dispatch = useDispatch();
    const { errorMessage } = useSelector(state => state.reducer);
    const { title, message, 
        dismissCallback, confirmCallback, canceCallback,
        disableManualDismiss = false } = props;

    useEffect(() => {
        setShowingAlertBox(message != undefined && message != "");

        return () => {
            setShowingAlertBox(false)
        }
    })

    return (
        <AwesomeAlert
            show={isShowingAlertBox}
            showProgress={false}
            title={title}
            message={message}
            closeOnTouchOutside={true}
            closeOnHardwareBackPress={true}
            showConfirmButton={confirmCallback != undefined}
            showCancelButton={canceCallback != undefined}
            confirmText={confirmCallback ? "OK" : undefined}
            cancelText={canceCallback ? "Cancel" : undefined}
            confirmButtonColor={Colors.light.tint}
            onConfirmPressed={() => {
                setShowingAlertBox(false);
                confirmCallback
            }}
            onCancelPressed={() => {
                setShowingAlertBox(false);
                canceCallback
            }}
            onDismiss={() => {
                dispatch({
                    type: REMOVE_EEROR_MESSAGE
                })
                dismissCallback
            }}
        />
    );
};
