import { useAssets } from 'expo-asset';
import React, { useState } from 'react';
import { StyleSheet, Image, TextInput, Pressable } from 'react-native';
import { useSelector, useDispatch } from 'react-redux'
import { AlertBox } from '../components/AlertBox';

import { Text, View } from '../components/Themed';
import Colors from '../constants/Colors';
import { signIn } from '../store/action';
import { RootState } from '../store/store';

export default function LoginScreen() {
    const [assets, error] = useAssets([require('../assets/images/logo.svg')]);
    const [loginID, onChangeLoginID] = useState('');
    const [password, onChangePassword] = useState('');
    const [formErrorMessage, setFormErrorMessage] = useState('');
    const dispatch = useDispatch();
    const { errorMessage } = useSelector((state: RootState) => state.reducer);

    let loginIdTextInput: TextInput | null;
    let passwordTextInput: TextInput | null;

    function signInAction() {
        if (!loginID && !password) {
            setFormErrorMessage('Please type in your Login ID and Passcode');
            loginIdTextInput?.focus();
        } else if (!loginID) {
            setFormErrorMessage('Please type in your Login ID');
            loginIdTextInput?.focus();
        } else if (!password) {
            setFormErrorMessage('Please type in your Passcode');
            passwordTextInput?.focus();
        } else {
            dispatch(signIn(loginID, password));
        }
    }

    let logoUrl = "";
    if (assets !== undefined && assets.length > 0) {
        logoUrl = assets[0]?.uri;
    }

    const [modalVisible, setModalVisible] = useState(false);

    return (
        <View style={[styles.container, {
            width: '100%'
        }]}>
            <View style={[styles.container, {
                // Try setting `flexDirection` to `"row"`.
                flexDirection: "row",
                justifyContent: "space-evenly"
            }]}>


                <View style={[styles.container, {
                    // Try setting `flexDirection` to `"column"`.
                    flexDirection: "column",
                    width: '30%'
                }]}>
                    <Image source={logoUrl} style={{ width:'80%', maxWidth: 300, height: 82 }} resizeMode='contain'
                        accessibilityLabel="Shop Enter" />
                    <Text
                        style={[styles.caption, {
                            width: '60%'
                        }]}
                        lightColor="rgba(0,0,0,0.8)"
                        darkColor="rgba(255,255,255,0.8)">
                        T&amp;A app is a quick-to-setup, easy to use time and attendance application which allow employees to Punch Card via cloud-based &amp; mobile devices in minute.
                    </Text>

                </View>


                <View style={[styles.container, {
                    width: '50%'
                }]}>
                    <View
                    style={[styles.container, {
                        // Try setting `flexDirection` to `"column"`.
                        width: '100%',
                        height: 'auto',
                        maxHeight: '100%',
                        flexDirection: 'column',
                        boxShadow: '0px 0px 8px 0 rgba(0, 0, 0, 0.2)',
                        paddingVertical: 120,
                        paddingHorizontal: 60
                    }]}>
                        <Text
                            style={[styles.caption, {
                                marginTop: 0,
                                lineHeight: 20,
                                textAlignVertical: 'center'
                            }]}
                            lightColor="rgba(0,0,0,0.8)"
                            darkColor="rgba(255,255,255,0.8)">
                            Please type in your Login ID and Passcode
                        </Text>

                        <TextInput
                            ref={(input) => { loginIdTextInput = input; }}
                            style={[styles.input, {
                                marginTop: 38,
                            }]}
                            onChangeText={onChangeLoginID} value={loginID} placeholder='Login ID'
                            blurOnSubmit={false}
                            onSubmitEditing={() => { passwordTextInput?.focus(); }} />
                        <TextInput
                            ref={(input) => { passwordTextInput = input; }}
                            style={[styles.input, {
                            }]}
                            onChangeText={onChangePassword} value={password} secureTextEntry={true} placeholder='Passcode'
                            blurOnSubmit={false}
                            onSubmitEditing={signInAction} />


                        <Pressable style={styles.button} onPress={signInAction}>
                            <Text style={styles.buttonText}>Sign in</Text>
                        </Pressable>

                        <Text
                            style={[styles.caption, {
                                width: '100%',
                                marginTop: 0,
                                textAlignVertical: 'center',
                                textAlign: 'left',
                                color: 'gray',
                                minHeight: 20
                            }]}
                            lightColor="rgba(0,0,0,0.8)"
                            darkColor="rgba(255,255,255,0.8)">
                            {formErrorMessage}
                        </Text>
                    </View>
                </View>
            </View>
            <View style={{
                position: 'absolute',
                bottom: 20,
                left: 12

            }}>
                <Text
                    style={styles.footerText}
                    lightColor="rgba(0,0,0,0.8)"
                    darkColor="rgba(255,255,255,0.8)">
                    &copy; {new Date().getFullYear()} People Technology Inc.
                </Text>
            </View>

            <AlertBox message={errorMessage?.toString()} />
        </View >
    );
}
const cellPropsCustom = {
    cellStyle: 'Basic',
    title: 'Basic Custom',
    backgroundColor: 'black',
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'space-evenly',
    },

    title: {
        fontSize: 20,
        fontWeight: 'bold',
    },
    separator: {
        marginVertical: 30,
        height: 1,
        width: '80%',
    },

    appName: {
        fontSize: 80
    },
    caption: {
        fontSize: 16,
        textAlign: 'center',
        marginTop: 50
    },
    branch: {
        marginTop: 40,
        fontSize: 40
    },
    qrCode: {
        width: 100,
        height: 100
    },
    footerText: {
        fontSize: 14
    },

    input: {
        width: '100%',
        height: 40,
        padding: 10,
        margin: 12,
        border: 'thin solid rgba(0, 0, 0, 0.2)',
        borderRadius: 5,
    },
    button: {
        width: '100%',
        height: 40,
        padding: 10,
        margin: 12,
        marginTop: 32,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 5,
        elevation: 3,
        backgroundColor: Colors.light.tint,
    },

    buttonText: {
        fontSize: 16,
        lineHeight: 21,
        fontWeight: 'bold',
        letterSpacing: 0.25,
        color: 'white',
    },
});
