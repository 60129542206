/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import { FontAwesome } from '@expo/vector-icons';
import { useNetInfo } from '@react-native-community/netinfo';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { NavigationContainer, DefaultTheme, DarkTheme } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React, { useEffect } from 'react';
import { ColorSchemeName, StyleSheet } from 'react-native';
import { useSelector, useDispatch } from 'react-redux'

import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';
import LoginScreen from '../screens/LoginScreen';
import CheckInScreen from '../screens/CheckInScreen';
import RecordScreen from '../screens/RecordScreen';
import SettingsScreen from '../screens/SettingsScreen';
import { RootStackParamList, RootTabParamList, RootTabScreenProps } from '../types';
import LinkingConfiguration from './LinkingConfiguration';
import { DESTROY_QR_CODE, SET_IS_NETWORK_CONNECTED, SET_SHOW_HISTORY_BEFORE_DATE_DIALOG } from '../store/types';
import { Button } from 'react-native-paper';
import { removeErrorMessage } from '../store/action';
import NoNetworkScreen from '../screens/NoNetworkScreen';

export default function Navigation({ colorScheme }: { colorScheme: ColorSchemeName }) {
  return (
    <NavigationContainer
      linking={LinkingConfiguration}
      theme={colorScheme === 'dark' ? DarkTheme : DefaultTheme}>
      <RootNavigator />
    </NavigationContainer>
  );
}

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const Stack = createNativeStackNavigator<RootStackParamList>();

function RootNavigator() {

  const dispatch = useDispatch();
  const { token, isNetworkConnected } = useSelector(state => state.reducer);
  const netInfo = useNetInfo();

  useEffect(() => {
    if (netInfo.isConnected != null && netInfo.isInternetReachable != null) {
      if (isNetworkConnected != true) {
        if (netInfo.isConnected == true && netInfo.isInternetReachable == true) {
          dispatch({
            type: SET_IS_NETWORK_CONNECTED,
            payload: true
          })
          dispatch(removeErrorMessage)
        }
      } else {
        if (netInfo.isConnected != true || netInfo.isInternetReachable != true) {
          dispatch({
            type: SET_IS_NETWORK_CONNECTED,
            payload: false
          })
          dispatch({
            type: DESTROY_QR_CODE
          })
        }
      }
    }

    return () => {
    }
  })

  if (token == null) {
    return (
      <Stack.Navigator>
        <Stack.Screen name="Login" component={LoginScreen} options={{ headerShown: false }} />
      </Stack.Navigator>
    )
  }

  if (!isNetworkConnected) {
    return (
      <Stack.Navigator>
        <Stack.Screen name="NoNetwork" component={NoNetworkScreen} options={{ headerShown: false }} />
      </Stack.Navigator>
    )
  }

  return (
    <Stack.Navigator>
      <Stack.Screen name="Root" component={BottomTabNavigator} options={{ headerShown: false }} />
    </Stack.Navigator>
  );
}

/**
 * A bottom tab navigator displays tab buttons on the bottom of the display to switch screens.
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */
const BottomTab = createBottomTabNavigator<RootTabParamList>();

function BottomTabNavigator() {
  const colorScheme = useColorScheme();
  const dispatch = useDispatch();
  const { history, historyBeforeDate } = useSelector(state => state.reducer);

  const enabledSelectHistoryBeforeDate = history != null;

  return (
    <BottomTab.Navigator
      initialRouteName="CheckIn"
      screenOptions={{
        tabBarActiveTintColor: Colors[colorScheme].tint,
      }}>
      <BottomTab.Screen
        name="CheckIn"
        component={CheckInScreen}
        options={{
          title: 'Check-in',
          headerShown: false,
          tabBarIcon: ({ color }) => <TabBarIcon name="map-marker" color={color} />,
        }}
      />
      <BottomTab.Screen
        name="Settings"
        component={SettingsScreen}
        options={{
          title: 'Settings',
          headerTitleAlign: 'center',
          headerStyle: { backgroundColor: Colors[colorScheme].primary },
          headerTintColor: Colors[colorScheme].primaryTint,
          tabBarIcon: ({ color }) => <TabBarIcon name="cog" color={color} />,
        }}
      />
      <BottomTab.Screen
        name="Record"
        component={RecordScreen}
        options={({ navigation }: RootTabScreenProps<'Record'>) => ({
          title: 'Record',
          headerTitleAlign: 'center',
          headerStyle: { backgroundColor: Colors[colorScheme].primary },
          headerTintColor: Colors[colorScheme].primaryTint,
          tabBarIcon: ({ color }) => <TabBarIcon name="history" color={color} />,
          headerLeftContainerStyle: { paddingLeft: 10, },
          headerLeft: () => (enabledSelectHistoryBeforeDate ? (
            <Button
              color={Colors[colorScheme].primaryTint}
              uppercase={false}
              onPress={() => {
                dispatch({
                  type: SET_SHOW_HISTORY_BEFORE_DATE_DIALOG,
                  payload: true
                })
              }} >
              {historyBeforeDate == null ? (`Today`) : (historyBeforeDate?.format(`YYYY-MM-DD`))}
            </Button>
          ) : null
          ),
        })}
      />
    </BottomTab.Navigator>
  );
}

/**
 * You can explore the built-in icon families and icons on the web at https://icons.expo.fyi/
 */
function TabBarIcon(props: {
  name: React.ComponentProps<typeof FontAwesome>['name'];
  color: string;
}) {
  return <FontAwesome size={30} style={{ marginBottom: -3 }} {...props} />;
}

const bottomTabStyles = StyleSheet.create({
  pressable: {
    marginLeft: 10,
    color: Colors.light.primaryTint
  },
  pressableDisabled: {
    marginLeft: 10,
    color: Colors.light.diabled
  },
});