import React, { useCallback, useEffect, useState } from "react";
import { StyleSheet, TextInput, Pressable, FlatList, Modal, Image } from "react-native";
import { DatePickerModal, en, registerTranslation, } from 'react-native-paper-dates-for-pt';
import { useSelector, useDispatch } from 'react-redux'
import { AlertBox } from '../components/AlertBox';
import { HistoryItem } from '../components/HistoryItem';

import { Text, View } from '../components/Themed';
import Colors from '../constants/Colors';
import { getHistory as getHistoryAction, resetHistory, updateHistoryRemarks } from '../store/action';
import { DESTROY_CURRENT_HISTORY_PAGE, DESTROY_HISTORY, DESTROY_HISTORY_BEFORE_DATE, DESTROY_TOTAL_HISTORY_PAGE, SET_SHOW_HISTORY_BEFORE_DATE_DIALOG, STORE_HISTORY_BEFORE_DATE } from '../store/types';
import moment from 'moment';
import { Portal } from 'react-native-paper';
import { RootState } from '../store/store';
import { Cell, Section, TableView } from 'react-native-tableview-simple';
import { useIsFocused } from '@react-navigation/native';
import { ScrollView } from "react-native-gesture-handler";
import { CalendarDate } from "react-native-paper-dates/lib/typescript/Date/Calendar";
import { color } from "react-native-reanimated";
registerTranslation('en', en)


export default function HistoryScreen() {
    const [accessKey, onChangeAccessKey] = useState('');
    const [showItem, setShowItem] = useState(null);
    const [historyItemRemarks, setHistoryItemRemarks] = useState('');
    const [isBeforeDateChanged, setIsBeforeDateChanged] = useState(false);
    const dispatch = useDispatch();
    const { token, errorMessage, history, totalHistoryPage, currentHistoryPage, showHistoryBeforeDateDialog, historyBeforeDate, showRecordThumbnail } = useSelector((state: RootState) => state.reducer);

    const isFocused = useIsFocused();
    let accessKeyTextInput: TextInput | null;

    useEffect(() => {
        return () => {
            if (isFocused != true && history != null) {
                resetShowItem()
                onChangeAccessKey('')
                // dispatch(destoryHistory)
                dispatch({
                    type: DESTROY_HISTORY
                })
                dispatch({
                    type: DESTROY_TOTAL_HISTORY_PAGE
                })
                dispatch({
                    type: DESTROY_CURRENT_HISTORY_PAGE
                })
                dispatch({
                    type: DESTROY_HISTORY_BEFORE_DATE
                })
                dispatch({
                    type: SET_SHOW_HISTORY_BEFORE_DATE_DIALOG,
                    payload: false
                })
            }
        }
    })

    const getHistory = (page: Number, beforeDate?: moment.Moment | null) => {
        // history == null : never get a history or not , (currentHistoryPage < totalHistoryPage) : list is not finish yet
        if (accessKey != '' && (history == null || (currentHistoryPage < totalHistoryPage) || isBeforeDateChanged)) {
            dispatch(getHistoryAction(token, accessKey, page, beforeDate));
        }
    }

    const renderItem = ({ item }: { item: Object }) => <HistoryItem showThumbnail={showRecordThumbnail} item={item} onPress={() => {
        setShowItem(item);
        setHistoryItemRemarks(item?.remarks != null ? item?.remarks : '');
    }} />
    const renderSeparator = () => (
        <View
            style={{
                backgroundColor: 'rgb(216, 216, 216)',
                height: 0.5,
            }}
        />
    );

    const saveRemarks = async () => {
        dispatch(updateHistoryRemarks(showItem, token, historyItemRemarks)).then((value) => {
            dispatch(resetShowItem)
            return Promise.resolve(`Success`)
        })
    }

    const resetShowItem = () => {
        setShowItem(null)
        setHistoryItemRemarks('')
    }

    //#region Date Picker callback and value
    const [datePickerDate, setDatePickerDate] = useState<Date | undefined>(undefined);
    const getBeforeDateRemarks = (date: CalendarDate) => {
        const beforeDate = moment(date);
        const today = moment();
        const isTodayDay = beforeDate.isSame(today, 'day')
        const confirmBeforeDate = isTodayDay ? null : beforeDate;

        dispatch({
            type: STORE_HISTORY_BEFORE_DATE,
            payload: confirmBeforeDate
        })
        dispatch(resetHistory())
        getHistory(1, confirmBeforeDate);

        dispatch({
            type: SET_SHOW_HISTORY_BEFORE_DATE_DIALOG,
            payload: false
        })
        setIsBeforeDateChanged(false);
    }
    const onDatePickerChange = useCallback(() => {
        setIsBeforeDateChanged(true);
    }, [setIsBeforeDateChanged]);
    const onDatePickerDismiss = useCallback(() => {
        dispatch({
            type: SET_SHOW_HISTORY_BEFORE_DATE_DIALOG,
            payload: false
        })

        setIsBeforeDateChanged(false);
    }, [setIsBeforeDateChanged, dispatch])
    const onDatePickerConfirm = useCallback((params) => {
        if (isBeforeDateChanged) {
            getBeforeDateRemarks(params.date)
        }
    }, [getBeforeDateRemarks, isBeforeDateChanged])

    useEffect(() => {
        if (historyBeforeDate != null) {
            setDatePickerDate(historyBeforeDate.toDate())
        } else {
            setDatePickerDate(moment().toDate())
        }
    }, [setDatePickerDate, historyBeforeDate])
    //#endregion

    return (<View style={[styles.container, {
        width: '100%'
    }]}>
        {history == null ? (
            <View style={styles.inputView}>
                <TextInput
                    ref={(input) => { accessKeyTextInput = input; }}
                    style={styles.input}
                    onChangeText={onChangeAccessKey} value={accessKey} secureTextEntry={true} placeholder='Access Key'
                    blurOnSubmit={false}
                    onSubmitEditing={() => { getHistory(currentHistoryPage + 1) }} />

                <Pressable style={styles.button} onPress={() => { getHistory(currentHistoryPage + 1) }}>
                    <Text style={styles.buttonText}>Unlock</Text>
                </Pressable>
            </View>
        ) : (
            <View style={[styles.container, {
                width: '100%'
            }]}>
                <FlatList style={[styles.listView]} data={history}
                    ItemSeparatorComponent={renderSeparator}
                    renderItem={renderItem}
                    keyExtractor={item => item.id}
                    onEndReached={() => { getHistory(currentHistoryPage + 1) }}
                />

                <Portal>
                    <DatePickerModal
                        locale="en-GB"
                        mode="single"
                        label="Select date"
                        visible={showHistoryBeforeDateDialog}
                        date={datePickerDate}
                        onChange={onDatePickerChange}
                        onDismiss={onDatePickerDismiss}
                        onConfirm={onDatePickerConfirm}
                        validRange={{
                            endDate: moment().toDate(),
                        }}
                    />

                    <Modal visible={showItem != null}
                        onDismiss={resetShowItem}
                        transparent={true}
                    >
                        <View style={styles.remarksModalBackground}>
                            <View style={styles.remarksModalContainer}>
                                <View style={styles.remarksModalHeader}>
                                    <Pressable onPress={resetShowItem}>
                                        <Text style={styles.remarksModalHeaderButton}>Back</Text>
                                    </Pressable>
                                </View>

                                <ScrollView
                                    style={styles.remarksModalContentScrollView}
                                    contentContainerStyle={styles.remarksModalContentContainer}
                                >
                                    <View style={[styles.remarksModalContentContainer, { 
                                        flexDirection: 'row',
                                        justifyContent: 'space-evenly',
                                        padding: 15
                                         }]}>
                                        <Image style={[styles.remarksModalContentImage, { flexGrow: 1 }]} resizeMode='contain' source={showItem?.photo_thumb_url} />
                                        <View style={[ styles.remarksModalContentDetails, { }]}>
                                            <View style={[ styles.remarksModalContentDetailsRow, {}]}>
                                                <Text style={[ styles.remarksModalContentDetailsRowTitle, {
                                                }]}>Name</Text>
                                                <Text style={[ styles.remarksModalContentDetailsRowValue, {
                                                }]}>{showItem?.staff?.first_name} {showItem?.staff?.last_name}</Text>
                                            </View>
                                            <View style={[ styles.remarksModalContentDetailsRowSeparator, {}]}></View>
                                            <View style={[styles.remarksModalContentDetailsRow]}>
                                                <Text style={[styles.remarksModalContentDetailsRowTitle]}>Staff ID</Text>
                                                <Text style={[styles.remarksModalContentDetailsRowValue]}>{showItem?.duty?.staff_shortcode_id}</Text>
                                            </View>
                                            <View style={[ styles.remarksModalContentDetailsRowSeparator, {}]}></View>
                                            <View style={[styles.remarksModalContentDetailsRow]}>
                                                <Text style={[styles.remarksModalContentDetailsRowTitle]}>Check-in Type</Text>
                                                <Text style={[styles.remarksModalContentDetailsRowValue]}>{showItem?.check_in_type_code}</Text>
                                            </View>
                                            <View style={[ styles.remarksModalContentDetailsRowSeparator, {}]}></View>
                                            <View style={[styles.remarksModalContentDetailsRow]}>
                                                <Text style={[styles.remarksModalContentDetailsRowTitle]}>Date &amp; Time</Text>
                                                <Text style={[styles.remarksModalContentDetailsRowValue]}>{showItem?.check_in_datetime != null ? moment(showItem?.check_in_datetime).format('YYYY-MM-DD HH:mm') : ''}</Text>
                                            </View>
                                            <View style={[ styles.remarksModalContentDetailsRowSeparator, {}]}></View>
                                            <View style={[styles.remarksModalContentDetailsRow, {flexDirection: 'column', flexGrow: 1}]}>
                                                <Text style={[styles.remarksModalContentDetailsRowTitle, { justifyContent: 'flex-start', alignSelf: 'flex-start' }]}>Remarks</Text>
                                                <TextInput
                                                    multiline={true}
                                                    style={[styles.remarksModalRemarksRowTextInput, { height: '100%'}]}
                                                    value={historyItemRemarks}
                                                    onChangeText={(remarks) => {
                                                        setHistoryItemRemarks(remarks);
                                                    }}
                                                />
                                            </View>
                                        </View>
                                        
                                        {/* <TableView appearance="light" style={styles.remarksModalContent}>
                                            <Section roundedCorners hideSurroundingSeparators separatorInsetRight={15}>
                                                <Cell cellStyle="RightDetail" title="Name" detail={`${showItem?.staff?.first_name} ${showItem?.staff?.last_name}`}></Cell>
                                                <Cell cellStyle="RightDetail" title="Staff ID" detail={showItem?.duty?.staff_shortcode_id}></Cell>
                                                <Cell cellStyle="RightDetail" title="Check-in Type" detail={showItem?.check_in_type_code}></Cell>
                                                <Cell cellStyle="RightDetail" title="Date &amp; Time" detail={showItem?.check_in_datetime != null ? moment(showItem?.check_in_datetime).format('YYYY-MM-DD HH:mm') : ''}></Cell>
                                                <Cell cellStyle='Basic' title="Remarks"
                                                    cellContentView={
                                                        <View
                                                            style={styles.remarksModalRemarksRow}
                                                        >
                                                            <Text
                                                                allowFontScaling
                                                                numberOfLines={1}
                                                                style={{ flex: 1, fontSize: 16 }}
                                                            >
                                                                Remarks
                                                            </Text>

                                                            <TextInput
                                                                multiline={true}
                                                                style={styles.remarksModalRemarksRowTextInput}
                                                                value={historyItemRemarks}
                                                                onChangeText={(remarks) => {
                                                                    setHistoryItemRemarks(remarks);
                                                                }}
                                                            />
                                                        </View>
                                                    }></Cell>
                                            </Section>
                                        </TableView> */}
                                    </View>
                                    <Pressable style={[styles.button, styles.remarksModelSaveButton]}
                                        onPress={saveRemarks}>
                                        <Text style={styles.buttonText}>Save</Text>
                                    </Pressable>
                                </ScrollView>
                            </View>
                        </View>
                    </Modal>
                </Portal>

            </View>
        )}

        <AlertBox message={errorMessage} />

    </View>);
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
    },

    inputView: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        alignItems: 'center',
        justifyContent: 'center'
    },

    input: {
        width: 350,
        height: 40,
        padding: 10,
        margin: 12,
        border: 'thin solid rgba(0, 0, 0, 0.2)',
        borderRadius: 5,
    },

    button: {
        width: 350,
        height: 40,
        padding: 10,
        margin: 12,
        marginTop: 32,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 5,
        elevation: 3,
        backgroundColor: Colors.light.tint,
    },

    buttonText: {
        fontSize: 16,
        lineHeight: 21,
        fontWeight: 'bold',
        letterSpacing: 0.25,
        color: 'white',
    },

    listView: {
        width: '100%'
    },

    remarksModalHeader: {
        width: '100%',
        backgroundColor: Colors.light.primary,
        padding: 20,
    },
    remarksModalContainer: {
        width: '70%',
        margin: 35,
        flex: 1,
        alignItems: `center`,
    },

    remarksModalBackground: {
        width: '100%',
        height: '100%',
        backgroundColor: `rgba(0, 0, 0, 0.5)`,
        flex: 1,
        alignItems: `center`,
    },

    remarksModalHeaderButton: {
        color: Colors.light.primaryTint,
        fontSize: 18
    },
    remarksModalContentScrollView: {
        width: '100%',
    },
    remarksModalContentContainer: {
        flexGrow: 1,
        justifyContent: 'space-between',
        backgroundColor: '#EFEFF4',
    },
    remarksModalContent: {
        height: '100%',
        backgroundColor: '#EFEFF4',
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 30,
        paddingBottom: 30,
        flexGrow: 1
    },

    remarksModalContentImage: {
        width: 180,
        height: '100%',
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 30,
        paddingBottom: 30,
        marginLeft: 20,
        marginRight: 20,
    },
    remarksModalContentDetails: {
        backgroundColor: '#FFF',
                                            marginLeft: 20,
                                            marginRight: 20,
                                            marginTop: 30,
                                            marginBottom: 30,
                                            flexGrow: 1,
                                            borderRadius: 15,
                                            borderWidth: 2,
                                            overflow: 'hidden',
                                            borderColor: '#FFF', 
                                            flexDirection: 'column'
    },

    remarksModalContentDetailsRow: {
        flexDirection: `row`, 
        padding: 15, 
        justifyContent: 'space-between'
    },
    remarksModalContentDetailsRowTitle: {
        color: 'rgb(0, 0, 0)',
        fontSize: 16,
        alignSelf: 'center',
        maxWidth: '100%',
        overflow: 'hidden',
        flexGrow: 1
    },
    remarksModalContentDetailsRowValue: {
        color: 'rgb(142, 142, 147)',
        fontSize: 16,
        alignSelf: 'center',
        maxWidth: '100%',
        overflow: 'hidden',
    },
    remarksModalContentDetailsRowSeparator: {
        height: 1,
        backgroundColor: 'rgb(200, 199, 204)',
        marginLeft: 15,
        marginRight: 15,
    },



    remarksModelSaveButton: {
        width: '96%',
        marginHorizontal: 'auto',
        marginVertical: 20,
        padding: 20,
        alignItems: 'center',
        justifyContent: 'center',
    },
    remarksModalRemarksRow: {
        alignItems: 'flex-start',
        flexDirection: 'column', 
        flex: 1,
        paddingVertical: 10,
        height: '80%',
    },
    remarksModalRemarksRowTextInput: {
        width: '100%',
        marginTop: 10,
        fontSize: 16,
        flexGrow: 1
    },



    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center'
    },
    modalView: {
        margin: 20,
        backgroundColor: 'white',
        borderRadius: 20,
        padding: 35,
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
    },
    buttonConfirm: {
        backgroundColor: Colors.light.primary,
        color: Colors.light.primaryTint,
    },
    buttonCancel: {
        backgroundColor: Colors.light.cancel,
        color: Colors.light.primaryTint,
    },
    modalText: {
        marginBottom: 15,
        textAlign: 'center',
    },
});
