import axios from 'axios';
import Constants from 'expo-constants';
import * as Location from 'expo-location';
import moment from 'moment';
import { Dispatch } from 'redux';

import {
    STORE_TOKEN,
    DESTROY_TOKEN,
    STORE_BRANCH,
    DESTROY_BRANCH,
    SET_EEROR_MESSAGE,
    STORE_QR_CODE,
    STORE_LAST_UPDATE_TIME,
    STORE_LOCATION,
    ADD_HISTORY,
    STORE_CURRENT_HISTORY_PAGE,
    STORE_TOTAL_HISTORY_PAGE,
    DESTROY_HISTORY,
    DESTROY_LAST_UPDATE_TIME,
    DESTROY_QR_CODE,
    DESTROY_LOCATION,
    DESTROY_TOTAL_HISTORY_PAGE,
    DESTROY_CURRENT_HISTORY_PAGE,
    REMOVE_EEROR_MESSAGE,
    UPDATE_HISTORY_ITEM,
    EMPTY_HISTORY,
    // SET_IS_FORCE_EEROR_MESSAGE,
    SET_IS_NETWORK_CONNECTED,
    UPDATE_BRANCH_MESSAGE,
    DESTROY_HISTORY_BEFORE_DATE,
    SET_SHOW_HISTORY_BEFORE_DATE_DIALOG,
    SET_SHOW_RECORD_THUMBNAIL,
} from "./types";


const FallbackErrorMessage = 'Oops! There\'s something wrong.';
const TokenInvalidErrorMessage = 'You have logged in on another device';
export const NetworkConnectionErrorMessage = `Please connect to network`;

export const removeErrorMessage = () => {
    return async (dispatch: Dispatch<any>) => {
        dispatch({
            type: REMOVE_EEROR_MESSAGE
        })
    }
}

export const signIn = (loginID: string, password: string) => {
    const host = Constants.manifest?.extra?.apiEndpoint;
    return async (dispatch: Dispatch<any>) => {
        let errorMessage = FallbackErrorMessage
        const response = await axios.post(`${host}/api/v1/shop/login`, {
            'login_id': loginID,
            'passcode': password
        }).catch(function (error) {
            if (error.response) {
                const errorContent = error.response.data;
                console.warn('errorContent?.message:', errorContent?.message);
                if (errorContent?.message != null && errorContent?.message != "") {
                    dispatch({
                        type: SET_EEROR_MESSAGE,
                        payload: errorContent?.message
                    })
                }
            } else {
                console.warn('Error Message:', error.message);
                dispatch({
                    type: SET_EEROR_MESSAGE,
                    payload: error.message
                })
            }
        });

        const responseContent = response?.data;
        if (responseContent != null) {
            dispatch(removeErrorMessage)
            
            if (responseContent.data?.token) {
                dispatch({
                    type: STORE_TOKEN,
                    payload: responseContent.data?.token
                });
                dispatch({
                    type: STORE_BRANCH,
                    payload: responseContent.data
                });
            } else {
                if (responseContent.message) {
                    errorMessage = responseContent.message;
                }

                dispatch({
                    type: SET_EEROR_MESSAGE,
                    payload: errorMessage
                })
            }
        }
    };
};

export const signOut = (errorMessage: string) => {
    return async (dispatch: Dispatch<any>) => {
        dispatch({
            type: DESTROY_TOKEN
        })
        dispatch({
            type: DESTROY_BRANCH
        })
        dispatch({
            type: DESTROY_QR_CODE
        })
        dispatch({
            type: DESTROY_LOCATION
        })
        dispatch({
            type: DESTROY_LAST_UPDATE_TIME
        })
        dispatch({
            type: DESTROY_HISTORY
        })
        dispatch({
            type: DESTROY_TOTAL_HISTORY_PAGE
        })
        dispatch({
            type: DESTROY_CURRENT_HISTORY_PAGE
        })
        dispatch({
            type: SET_SHOW_RECORD_THUMBNAIL,
            payload: true
        })

        if (errorMessage) {
            /** Display error message */
            dispatch({
                type: SET_EEROR_MESSAGE,
                payload: errorMessage
            })
        }
    };
}

export const prefetchForegroundPermission = () => {
    console.log('prefetchForegroundPermission');
    return async (dispatch: Dispatch<any>) => {
        const { status } = await Location.requestForegroundPermissionsAsync();
        foregroundPermission = status
        dispatch({
            type: SET_IS_NETWORK_CONNECTED,
            payload: status === 'granted'
        })
    }
}

var foregroundPermission = null;
var isFetchingQRCode = false;


export const getQRCode = (token: string) => {
    if (isFetchingQRCode == true) {
        return;
    }

    isFetchingQRCode = true;
    const host = Constants.manifest?.extra?.apiEndpoint;
   
  
    if (token != null && token != "") {
        console.log("Passed");
        return async (dispatch: Dispatch<any>) => {

            console.log("RequestForegroundPermissionAsync")
            // Get location if need

            if (foregroundPermission == null) {
                let { status } = await Location.requestForegroundPermissionsAsync();
                foregroundPermission = status
                console.log("status:", status);
            }




            if (foregroundPermission !== 'granted') {
                /** Sign out when no permission */
                dispatch(signOut('Permission to access location was denied'))
                return Promise.reject;
            }

            let location = await Location.getCurrentPositionAsync({});
            // let location = await Location.getLastKnownPositionAsync({});


            console.log("API Request:", `${host}/api/v1/shop/qrcode`);
            //Fetch QRcode
            const response = await axios.post(`${host}/api/v1/shop/report`, {
                'latitude': location?.coords?.latitude ? location?.coords?.latitude : 0,
                'longitude': location?.coords?.longitude ? location?.coords?.longitude : 0,
                'altitude': location?.coords?.altitude ? location?.coords?.altitude : 0,
            }, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }).catch(function (error) {
                if (error.response) {
                    const errorContent = error.response.data;
                    if (error.response.status == 401) {
                        /** Sign out when response 401 */
                        dispatch(signOut(TokenInvalidErrorMessage))
                        return Promise.reject(TokenInvalidErrorMessage);
                    } else {
                        if (errorContent?.message != null && errorContent?.message != "") {
                            dispatch({
                                type: SET_EEROR_MESSAGE,
                                payload: errorContent?.message
                            })
                        }
                    }
                } else {
                    console.error('Error Message:', error.message);
                    let message = error.message;
                    if (message == "Network Error") {
                        message = NetworkConnectionErrorMessage;
                    }
                    dispatch({
                        type: SET_EEROR_MESSAGE,
                        payload: message
                    })
                }

                dispatch({
                    type: DESTROY_QR_CODE,
                });
                isFetchingQRCode = false;

                return Promise.reject;
            });

            const responseContent = response?.data;

            if (responseContent != null) {
                dispatch({
                    type: SET_EEROR_MESSAGE,
                    payload: ""
                });

                if (responseContent.message) {
                    /** Display error message */
                    dispatch({
                        type: SET_EEROR_MESSAGE,
                        payload: responseContent.message
                    })
                }
                if (responseContent.data?.qrcode) {
                    dispatch({
                        type: STORE_QR_CODE,
                        payload: responseContent.data?.qrcode
                    });

                    dispatch({
                        type: UPDATE_BRANCH_MESSAGE,
                        payload: responseContent.data?.branch_message
                    });

                    dispatch({
                        type: STORE_LAST_UPDATE_TIME,
                        payload: moment().format('YYYY-MM-DD HH:mm:ss')
                    });
                    dispatch({
                        type: STORE_LOCATION,
                        payload: location
                    });

                    isFetchingQRCode = false;

                    return Promise.resolve;
                }
            }

            isFetchingQRCode = false;
            return Promise.reject;
        };
    } else {
        console.log("Rejected");
        isFetchingQRCode = false;
        return Promise.reject;
    }
}

export const getHistory = (token: string, accessKey: string, page: number, beforeDate?: moment.Moment | null) => {
    return async (dispatch: Dispatch<any>) => {
        const host = Constants.manifest?.extra?.apiEndpoint;

        const response = await axios.get(`${host}/api/v1/shop/history`, {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            params: {
                'page': page,
                'access_key': accessKey,
                'beforeDate': beforeDate?.toDate()
            }
        }).catch(function (error) {
            if (error.response) {
                const errorContent = error.response.data;
                if (error.response.status == 401) {
                    /** Sign out when response 401 */
                    dispatch(signOut(TokenInvalidErrorMessage))
                } else {
                    if (errorContent?.message != null && errorContent?.message != "") {
                        dispatch({
                            type: SET_EEROR_MESSAGE,
                            payload: errorContent?.message
                        })
                    }
                }
            } else {
                console.error('Error Message:', error.message);
            }
        });

        const responseContent = response?.data;

        if (responseContent != null) {
            if (responseContent.data) {
                dispatch({
                    type: ADD_HISTORY,
                    payload: responseContent.data
                });
                dispatch({
                    type: STORE_CURRENT_HISTORY_PAGE,
                    payload: responseContent.page ? responseContent.page : page
                });
                dispatch({
                    type: STORE_TOTAL_HISTORY_PAGE,
                    payload: responseContent.lastPage ? responseContent.lastPage : page
                });
            }
            if (responseContent.message) {
                /** Display error message */
                dispatch({
                    type: SET_EEROR_MESSAGE,
                    payload: responseContent.message
                })
            }
        }
    }
}

export const destoryHistory = () => {
    return async (dispatch: Dispatch<any>) => {
        dispatch({
            type: DESTROY_HISTORY
        })
        dispatch({
            type: DESTROY_TOTAL_HISTORY_PAGE
        })
        dispatch({
            type: DESTROY_CURRENT_HISTORY_PAGE
        })
        dispatch({
            type: DESTROY_HISTORY_BEFORE_DATE
        })
        dispatch({
            type: SET_SHOW_HISTORY_BEFORE_DATE_DIALOG,
            payload: false
        })
    };
}

export const resetHistory = () => {
    return async (dispatch: Dispatch<any>) => {
        dispatch({
            type: EMPTY_HISTORY
        })
        dispatch({
            type: DESTROY_TOTAL_HISTORY_PAGE
        })
        dispatch({
            type: DESTROY_CURRENT_HISTORY_PAGE
        })
    };
}

export const updateHistoryRemarks = (item: Object, token: string, newRemarks: string) => {
    return async (dispatch: Dispatch<any>) => {
        const host = Constants.manifest?.extra?.apiEndpoint;

        const response = await axios.post(`${host}/api/v1/shop/history/${item.id}/remarks`, {
            'remarks': newRemarks
        }, {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).catch(function (error) {
            if (error.response) {
                const errorContent = error.response.data;
                if (error.response.status == 401) {
                    /** Sign out when response 401 */
                    dispatch(signOut(TokenInvalidErrorMessage))
                    return Promise.reject(TokenInvalidErrorMessage);
                } else {
                    if (errorContent?.message != null && errorContent?.message != "") {
                        dispatch({
                            type: SET_EEROR_MESSAGE,
                            payload: errorContent?.message
                        })
                    }
                }
            } else {
                console.error('Error Message:', error.message);
                dispatch({
                    type: SET_EEROR_MESSAGE,
                    payload: error.message
                })
            }

            return Promise.reject;
        });

        const responseContent = response?.data;
        if (responseContent != null) {
            if (responseContent.message) {
                /** Display error message */
                dispatch({
                    type: SET_EEROR_MESSAGE,
                    payload: responseContent.message
                })
            }
            if (responseContent.data) {
                dispatch({
                    type: UPDATE_HISTORY_ITEM,
                    paylaod: responseContent.data
                })

                return Promise.resolve;
            }
            return Promise.reject;
        }
    }
}

export const setNetworkDisconnected = () => {
    return async (dispatch: Dispatch<any>) => {
        dispatch({
            type: SET_IS_NETWORK_CONNECTED,
            payload: false
        })
        dispatch({
            type: DESTROY_QR_CODE
        })
        dispatch({
            type: SET_EEROR_MESSAGE,
            payload: NetworkConnectionErrorMessage
        })
    };
}

export const setNetworkConnected = () => {
    return async (dispatch: Dispatch<any>) => {
        dispatch({
            type: SET_IS_NETWORK_CONNECTED,
            payload: true
        })
        dispatch(removeErrorMessage)
    };
}