import moment from "moment";
import React from "react";
import { StyleSheet, Text, TouchableOpacity, Image } from 'react-native';
import { View } from "./Themed";

export const HistoryItem = (props: {
    item: Object,
    onPress: Function,
    showThumbnail: boolean,
}) => {

    const { item, onPress, showThumbnail } = props;

    const remarks = item?.remarks?.replaceAll('\n', ' ').replaceAll('\r', ' ');

    return (
        <TouchableOpacity onPress={onPress} style={[styles.item]}>
            <Image style={[styles.image, { display: showThumbnail? 'flex' : 'none'}]} resizeMode='contain' source={item.photo_thumb_url} />
            <View style={[styles.nameContainer]}>
                <Text style={[styles.staffShortcodeId]} >{item.duty.staff_shortcode_id}</Text>
                <Text style={[styles.fullName]} >{item.staff.first_name} {item.staff.last_name}</Text>
            </View>
            <Text style={[styles.remarks]}
            numberOfLines={0}
            ellipsizeMode={'tail'}
            >{remarks}</Text>
            <View style={{}}>
                <Text style={[styles.checkInTime]} >{moment(item.check_in_datetime).format('YYYY-MM-DD HH:mm')}</Text>
                <Text style={[styles.checkInType]} >{item.check_in_type_code}</Text>
            </View>
        </TouchableOpacity>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    item: {
        flex: 1,
        padding: 20,
        flexDirection: "row",
        alignItems: "center",
        height: 120
    },
    image: {
        width: 60,
        height: 80,
        marginRight: 20,
    },
    nameContainer: {
        marginRight: 20
    },
    staffShortcodeId: {
        fontSize: 16,
    },
    fullName: {
        fontSize: 12,
    },
    remarks: {
        flex: 1,
        alignSelf: "flex-start",
        fontSize: 14,
        marginRight: 20,
    },
    checkInTime: {
        fontSize: 16,
        textAlign: "center"
    },
    checkInType: {
        fontSize: 12,
        textAlign: "center"
    },
});