export const STORE_TOKEN = 'STORE_TOKEN';
export const DESTROY_TOKEN = 'DESTROY_TOKEN';

export const STORE_BRANCH = 'STORE_BRANCH';
export const DESTROY_BRANCH = 'DESTROY_BRANCH';
export const UPDATE_BRANCH_MESSAGE = 'UPDATE_BRANCH_MESSAGE';

export const STORE_QR_CODE = 'STORE_QR_CODE';
export const DESTROY_QR_CODE = 'DESTROY_QR_CODE';

export const STORE_LOCATION = 'STORE_LOCATION';
export const DESTROY_LOCATION = 'DESTROY_LOCATION';

export const STORE_LAST_UPDATE_TIME = 'STORE_LAST_UPDATE_TIME';
export const DESTROY_LAST_UPDATE_TIME = 'DESTROY_LAST_UPDATE_TIME';

export const SET_EEROR_MESSAGE = 'SET_EEROR_MESSAGE';
export const REMOVE_EEROR_MESSAGE = 'REMOVE_EEROR_MESSAGE';

// export const SET_IS_FORCE_EEROR_MESSAGE = 'SET_IS_FORCE_EEROR_MESSAGE';

export const ADD_HISTORY = 'ADD_HISTORY';
export const DESTROY_HISTORY = 'DESTROY_HISTORY';
export const EMPTY_HISTORY = 'EMPTY_HISTORY';

export const STORE_TOTAL_HISTORY_PAGE = 'ADD_TOTAL_HISTORY_PAGE';
export const DESTROY_TOTAL_HISTORY_PAGE = 'DESTROY_TOTAL_HISTORY_PAGE';

export const STORE_CURRENT_HISTORY_PAGE = 'ADD_CURRENT_HISTORY_PAGE';
export const DESTROY_CURRENT_HISTORY_PAGE = 'DESTROY_CURRENT_HISTORY_PAGE';

export const UPDATE_HISTORY_ITEM = 'UPDATE_HISTORY_ITEM';

export const STORE_HISTORY_BEFORE_DATE = 'STORE_HISTORY_BEFORE_DATE';
export const DESTROY_HISTORY_BEFORE_DATE = 'DESTROY_HISTORY_BEFORE_DATE';

export const SET_SHOW_HISTORY_BEFORE_DATE_DIALOG = 'SET_SHOW_HISTORY_BEFORE_DATE_DIALOG';

export const SET_IS_NETWORK_CONNECTED = 'SET_IS_NETWORK_CONNECTED';

export const SET_SHOW_RECORD_THUMBNAIL = 'SET_SHOW_RECORD_THUMBNAIL';